<template>
    <main class="main" v-bar>
        <div>
            <div class="container">
                <div class="pre_title">{{ $t('news.subtitle') }}</div>
                <h2>{{ $t('news.title') }}</h2>
                <div class="cards_wrap" v-if="list.length">
                    <div class="card_wrap" v-for="item in list" :key="item.id">
                        <div class="card" v-if="item.type !== 'video'">
                            <div class="card_image" v-if="item.src">
                                <img :src="item.src" alt="card_img">
                            </div>
                            <div class="card_content">
                                <div class="card_title" v-if="item[currentLanguage].title">{{ item[currentLanguage].title | truncate(100) }}</div>
                                <div class="card_text">{{ item[currentLanguage].text | truncate(250) }}</div>
                                <a :href="item.ref" target="_blank" rel="noreferrer" class="card_link">{{ $t('home.activities.next') }}</a>
                            </div>
                        </div>
                        <a v-if="item.type === 'video'" class="card_video video_js" :href="item.ref">
                            <img :src="item.src" alt="card_img">
                            <div class="card_video_text">{{ item[currentLanguage].title | truncate(100) }}</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { TimelineMax } from 'gsap';
import { mapGetters } from 'vuex';
import { Trans } from '@/shared/utils/TranslateMiddleware';
import truncate from '@/shared/filters/truncate'; // eslint-disable-line

export default {
    metaInfo() {
        return {
            title: `${this.$t('header.title')} - ${this.$t('menu.news')}`,
        }
    },
    name: 'HomePageComponent',
    computed: {
        ...mapGetters('news', {
            list: 'getList'
        }),
        currentLanguage () {
            return Trans.currentLanguage;
        }
    },
    watch: {
        list() {
            setTimeout(() => {
                this.starting();
            });
        }
    },
    methods: {
        starting() {
            $('.video_js').fancybox({ // eslint-disable-line
                openEffect: 'fade',
                closeEffect: 'fade',
                helpers: {
                    media : {}
                },
                thumbs: {
                    autoStart: true,
                    axis: 'x'
                }
            });

            const tl = new TimelineMax();
            tl.staggerTo(('.card_wrap'), 0.3, {opacity: 1, x: 0}, 0.15);
        }
    },
    mounted() {
        this.starting();
    }
}
</script>

<style lang="scss" scoped>

</style>